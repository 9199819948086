<template>
  <div class="map">
    <div class="hand-anim-wrap" v-if="isMobile && mapInstructions">
      <p>תזוזה במפה</p>
      <img
        src="img/hand_animation.gif"
        class="hand-anim"
        alt=""
        v-if="isMobile"
      />
    </div>
    <div class="wrap">
      <div class="location-details" v-if="!isMobile">
        <p v-for="(btn, i) in btns" :key="i" dir="auto">
          {{ btn.frame }} - {{ btn.title }}
        </p>
      </div>
      <div class="map-background" ref="map">
        <button
          :class="`teleport-btn ${
            btn.frame === currentFrame ? 'current-place' : ''
          }`"
          v-for="(btn, i) in btns"
          :key="i"
          :style="{ top: btn.top + 'px', left: btn.left + 'px' }"
          @click="$emit('mapclick', btn.frame)"
        >
          {{ btn.frame }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// require("aframe");

export default {
  name: "Map",
  props: ["currentFrame", "isMobile", "mapInstructions", "btns"],
  data: () => {
    return {
      mapInstructions: true,

    };
  },

  mounted() {
    setTimeout(() => {
      this.$emit("mapinstructions");
    }, 3500);
  },
  methods: {
    resize: function () {
      console.log("sizin");
    },
  },
  computed: {
    // size: function () {
    //   // `this` points to the vm instance
    //   let map = document.querySelector(".map");
    //   // var mapHeight = map.offsetHeight;
    //   // var mapWidth = map.offsetWidth;
    //   console.log(map);
    //   console.log(map.innerWidth);
    //   return map;
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrap {
  width: 100%;
  height: 100%;
  max-width: 861px;
  max-height: 688px;
  overflow: auto;
}
.location-details {
  position: absolute;
  top: 70px;
  right: 10px;
  z-index: 3;
  background: rgba(255, 166, 0, 0.7);
  border: 5px dashed orange;
  padding: 10px;
  font-weight: 700;
  p {
    margin: 0 5px;
  }
}
.hand-anim-wrap {
  background: rgba(255, 166, 0, 0.9);
  text-decoration: underline;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.4);
  z-index: 1;
  pointer-events: none;
}

.map-background {
  position: relative;
  width: 861px;
  height: 688px;
  background: url("/img/map-lowres.webp");
  background-repeat: no-repeat;
  background-size: cover;
  .teleport-btn {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: red;
    font-weight: 900;
    font-size: 13px;
    border: 4px solid red;
    transition: 0.5s;
    &:hover {
      border: 3px solid red;
    }
    &.current-place {
      color: white;
      background: #02679d;
      border: 4px solid #02679d;
    }
  }
}
// @media only screen and (max-height: 720px) {
//   .map-background {
//     transform: translate(-50%, -50%) scale(0.7);
//   }
// }
</style>
