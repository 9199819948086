<template>
  <div class="scene">
    <div class="frame-info">
      <h1 class="frame-title">תחנה: {{ imgList[currentFrame].step }}</h1>
      <h1 class="frame-title" dir="auto">
        {{ btns[imgList[currentFrame].step - 1].title }}
      </h1>
    </div>

    <div class="loading-wrap" v-if="!loaded">
      <div class="lds-dual-ring">
        <span class="txt">Loading</span>
      </div>
    </div>
    <transition name="bounce">
      <div class="dialog-wrap item-info" v-if="infoOpen">
        <div class="dialog ui-item gradient">
          <button class="exit" v-if="!isMobile" @click.stop="exitInfo"></button>
          <button class="exit" v-else @touchstart.stop="exitInfo"></button>
          <div
            class="text-wrap"
            v-for="(paragraph, i) in isOnMain
              ? infoList[currentFrame].main[infoSector]
              : infoList[currentFrame].sub"
            :key="i"
          >
            <p class="text" dir="auto">
              {{ paragraph }}
            </p>
          </div>
        </div>
      </div>
    </transition>
    <!-- <div class="scene-border" v-if="infoOpen"></div> -->

    <transition name="bounce">
      <div
        :class="`dialog-wrap ${!isMobile ? 'desktop' : ''} pdf`"
        v-if="pdfOpen"
      >
        <div class="dialog ui-item gradient">
          <button
            class="exit"
            @click.stop.prevent="pdfOpen = !pdfOpen"
          ></button>
          <div class="pdf-wrap">
            <!-- <div class="lds-dual-ring" v-if="!imgLoaded">
              <span class="txt">Loading</span>
            </div> -->
            <div class="placeholder"></div>
            <img src="work-instructions-img.webp" alt="Loading..." />
          </div>
        </div>
      </div>
    </transition>

    <transition name="bounce">
      <div
        :class="`dialog-wrap ${!isMobile ? 'desktop' : ''} instructions`"
        v-if="instructionsOpen"
      >
        <div class="dialog ui-item gradient">
          <button
            class="exit"
            @click.stop.prevent="instructionsOpen = !instructionsOpen"
          ></button>

          <Instructions :isMobile="isMobile" />
        </div>
      </div>
    </transition>
    <div class="ui" v-if="loaded && !infoOpen && !instructionsOpen && !pdfOpen">
      <div class="top ui-item">
        <!-- {{ currentFrame }} -->
        <button
          :style="`background-image: url(${'img/icons/info-full.svg'});`"
          v-if="!isMapOpen"
          class="ui-btn info"
          style=""
          @click="instructionsOpen = !instructionsOpen"
          title="מידע"
        ></button>
        <button
          :style="`background-image: url(${'img/icons/map-full.svg'});`"
          class="ui-btn map"
          @click="(isMapOpen = !isMapOpen), (instructionsOpen = false)"
          title="מפה"
        ></button>
        <button
          :style="`background-image: url(${'img/icons/home-full.svg'});`"
          class="ui-btn map pdf"
          @click="pdfOpen = true"
          title="הוראת עבודה"
          data-title="הוראת עבודה"
        ></button>
        <button
          :style="
            isFullScreen
              ? `background-image: url(${'img/icons/min-full.svg'});`
              : `background-image: url(${'img/icons/full-full.svg'});`
          "
          v-if="!isMobile"
          :class="`ui-btn gradient toggle-screen ${
            isFullScreen ? 'min' : 'full'
          }`"
          :title="!isFullScreen ? 'מסך מלא' : 'הקטן מסך'"
          @click="$emit('toggleScreen')"
        ></button>
      </div>
      <div class="bottom" v-if="!isMapOpen">
        <button
          v-if="currentFrame !== imgList.length - 1"
          title="הבא"
          class="next arrow ui-btn ui-item gradient"
          @click="next"
        ></button>
        <button
          v-if="currentFrame !== 0"
          title="הקודם"
          class="prev arrow ui-btn ui-item gradient"
          @click="prev"
        ></button>
      </div>
    </div>
    <!-- <div class="ui during-load" v-else>
      <div class="top ui-item">
        <a
          href="work-instructions.pdf"
          target="_blank"
          :style="`background-image: url(${'img/icons/home-full.svg'});`"
          class="ui-btn map pdf"
          @click="openPdf"
          title="הוראת עבודה"
          data-title="הוראת עבודה"
        ></a>
      </div>
    </div> -->
    <a-scene
      vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false"
      loading-screen="enabled: false"
    >
      <a-assets
        timeout="15000"
        @timeout="loaded = true"
        @loaded="loaded = true"
      >
        <img id="borderRed" src="img/border_red.svg" />
        <img id="borderYellow" src="img/border_yellow.svg" />
        <img id="objectInfo" src="img/objectInfo.svg" />
        <canvas id="canvas" />
        <canvas id="blur-canvas" />
        <span v-for="(item, index) in imgList" :key="index">
          <img
            :id="`preload${item.name}`"
            :src="`img/frames/${isMobile ? 'mobile' : 'pc'}/${item.name}.webp`"
          />
          <img
            v-if="item.hasSub"
            :id="`preload${item.step}-s`"
            :src="`img/frames/${isMobile ? 'mobile' : 'pc'}/${
              item.step
            }-s.webp`"
          />
        </span>
      </a-assets>

      <a-entity id="rig">
        <a-entity
          position="0 1.6 0"
          rotation="0 0 0"
          ref="camera"
          id="camera"
          :camera="`fov: ${isMobile ? 110 : 90}; zoom: 1`"
          cursor="rayOrigin: mouse"
          raycaster="objects: .intractable; interval: 100"
          look-controls="magicWindowTrackingEnabled: false"
          rotation-reader
        >
        </a-entity>
        <TripodBlur v-if="!isMobile" />
      </a-entity>

      <!-- <a-entity v-if="renderedImgs < 17"> -->
      <!-- <a-sky
        v-for="(img, i) in imgList"
        :key="i"
        :src="`#preload${img.name}`"
        rotation="0 0 0"
      ></a-sky> -->
      <!-- </a-entity> -->
      <a-sky
        class="sky"
        :src="`#preload${imgList[currentFrame].step}-${isOnMain ? 'm' : 's'}`"
        rotation="0 0 0"
      ></a-sky>
      <!--  -->

      <a-entity v-if="isOnMain">
        <a-entity
          v-for="(item, i) in currScenario.clickAreasMain"
          :key="i"
          :rotation="item.rot"
        >
          <a-image
            class="intractable"
            ref="area"
            :src="
              currScenario.subAreas.length === 0
                ? '#borderRed'
                : '#borderYellow'
            "
            :width="item.width"
            :height="item.height"
            position="2 1.6 0"
            rotation="0 90 0"
            @mousedown="clickArea"
            @mouseenter="hoverAnim(true)"
            @mouseleave="hoverAnim(false)"
            visible="true"
            look-at="[camera]"
          >
          </a-image>
          <a-circle
            ref="info"
            class="intractable"
            src="#objectInfo"
            radius="0.3"
            :position="locationByArea(item)"
            :rotation="item.questionRot !== '' ? item.questionRot : '0 -90 0'"
            @mousedown="questionMarkClick(i)"
            @mouseenter="hoverAnimInfo(true)"
            @mouseleave="hoverAnimInfo(false)"
          >
          </a-circle>
          <a-image
            v-if="currentFrame === 7 && infoOpen"
            position="2 6.8 -0.2"
            rotation="0 90 -30"
            scale="1.7 1.2 1.2"
            src="img/icons/dir-arrow.webp"
            class="dir-arrow"
          ></a-image>
          <div class="qRotDebug devtools" v-if="dev">
            <input type="text" v-model="item.questionRot" />
          </div>
        </a-entity>
      </a-entity>
      <a-entity v-else>
        <a-entity
          v-for="(item, i) in currScenario.subAreas"
          :key="i"
          :rotation="item.rot"
        >
          <a-image
            class="intractable"
            ref="subArea"
            src="#borderRed"
            :width="item.width"
            :height="item.height"
            position="2 1.6 0"
            rotation="0 90 0"
            visible="true"
          >
          </a-image>
          <a-circle
            ref="info"
            class="intractable"
            src="#objectInfo"
            radius="0.3"
            :position="locationByArea(item)"
            :rotation="item.questionRot ? item.questionRot : '0 -90 0'"
            @mousedown="questionMarkClick(i)"
          >
          </a-circle>
          <div class="qRotDebug devtools" v-if="dev">
            <input type="text" v-model="item.questionRot" />
          </div>
        </a-entity>
      </a-entity>
    </a-scene>
    <Map
      v-if="isMapOpen"
      :currentFrame="imgList[currentFrame].step"
      :btns="btns"
      :isMobile="isMobile"
      :mapInstructions="mapInstructions"
      @mapclick="
        (isMapOpen = false),
          (isOnMain = true),
          findFrame($event),
          resetCameraView()
      "
      @mapinstructions="mapInstructions = false"
      @closemap="isMapOpen = false"
    />
  </div>
</template>

<script>
// require("aframe");
import Map from "@/components/Map.vue";
import Instructions from "@/components/Instructions.vue";
import TripodBlur from "@/components/TripodBlur.vue";

export default {
  name: "Scene",
  components: {
    Map,
    Instructions,
    TripodBlur,
  },
  props: ["imgList", "infoList", "btns"],
  data: () => {
    return {
      currentFrame: 0,
      isMobile: false,
      imgLoaded: false,
      isFullScreen: false,
      isMapOpen: false,
      instructionsOpen: true,
      pdfOpen: false,
      mapInstructions: true,
      infoColor: "#ad2e2e",
      infoColorOrigin: "#ad2e2e",
      infoColorHover: "#cc5656",
      camRot: "0 1.7 0",
      camDirTest: {},
      isOnMain: true,
      loaded: false,
      infoOpen: false,
      camLoaded: false,
      infoSector: null,
      renderedImgs: 0,
      dev: false,
    };
  },
  computed: {
    // a computed getter

    currScenario: function () {
      // `this` points to the vm instance
      return this.imgList[this.currentFrame];
    },
  },
  mounted() {
    if (AFRAME.utils.device.isMobile()) {
      this.isMobile = true;
    }
    document.addEventListener("keydown", (e) => {
      // console.log(e);
      if (e.keyCode === 37) this.next();
      if (e.keyCode === 39) this.prev();
      // log.textContent += ` ${e.code}`;
    });
    this.$refs.camera.addEventListener("loaded", () => {
      this.camLoaded = true;
      this.resetCameraView();
    });
  },
  methods: {
    camDirectDebug: function () {
      var camera = this.$refs.camera;
      console.log(
        "x: " + camera.components["look-controls"].pitchObject.rotation.x
      );
      console.log(
        "y: " + camera.components["look-controls"].yawObject.rotation.y
      );
    },
    exitInfo: function () {
      if (!this.infoOpen) {
        this.infoOpen = true;
      }
      if (this.infoOpen) {
        this.infoOpen = false;
      }
    },
    findFrame: function (frame) {
      let il = this.imgList;
      this.currentFrame = il.findIndex(({ step }) => step == frame);
    },

    openPdf: function (e) {
      e = e || window.event;

      // find the url and title to set
      var href = this.getAttribute("href");
      var newTitle = this.getAttribute("data-title");
      // or if you work the title out some other way...
      // var newTitle = "Some constant string";

      // open the window
      var newWin = window.open(href, "_blank");

      // add a load listener to the window so that the title gets changed on page load
      newWin.addEventListener("load", function () {
        newWin.document.title = newTitle;
      });

      // stop the default `a` link or you will get 2 new windows!
      event.returnValue = false;
    },
    isVisible: function (item) {
      let visible = true;
      console.log(item);
      if (this.renderedImgs === 17) {
        item.step == this.currentFrame ? (visible = true) : (visible = false);
      }
      return visible;
    },
    questionMarkClick: function (i) {
      // if (this.isOnMain)
      if (!this.infoOpen) {
        console.log("click");
        this.infoSector = i;
        this.infoOpen = true;
      }
    },
    clickArea: function () {
      if (this.currScenario.hasSub) {
        this.isOnMain = !this.isOnMain;
        this.infoOpen = false;
        this.resetCameraView();
      }
    },
    locationByArea: function (item) {
      let loc;
      let w = item.width;
      let h = item.height;
      let pos = item.infoLoc;
      if (pos === "right") loc = `2 1.6 ${w - w / 3}`;
      if (pos === "left") loc = `2 1.6 -${w - w / 3}`;
      if (pos === "top") loc = `2 ${1.7 + (h - h / 4)} 0`;
      if (pos === "bottom") loc = `2 ${1.5 - (h - h / 4)} 0`;
      return loc;
    },
    hoverAnim: function (entered) {
      let area = this.$refs.area[0];

      if (this.currScenario.hasSub) {
        if (entered) {
          area.setAttribute(
            "animation",
            "property: scale; to: 1.05 1.05 1.05; easing: easeInOutElastic; loop: 1; dur: 300"
          );
        } else {
          area.setAttribute(
            "animation",
            "property: scale; to: 1 1 1; easing: easeInOutElastic; loop: 1; dur: 300"
          );
        }
      }
    },

    hoverAnimInfo: function (entered) {
      let info = this.$refs.info[0];

      if (entered) {
        info.setAttribute(
          "animation",
          "property: scale; to: 0.95 0.95 0.95; easing: easeInOutElastic; loop: 1; dur: 200"
        );
      } else {
        info.setAttribute(
          "animation",
          "property: scale; to: 1 1 1; easing: easeInOutElastic; loop: 1; dur: 300"
        );
      }
    },
    hit: function () {
      console.log("hit");
    },
    next: function () {
      if (this.currentFrame !== this.imgList.length) {
        this.isOnMain = true;
        if (this.currentFrame === this.imgList.length) {
          this.currentFrame = 1;
        } else this.currentFrame++;
        this.resetCameraView();
      }
      // return this.imgList[this.currentFrame];
    },
    prev: function () {
      if (this.currentFrame !== 0) {
        if (this.isOnMain === false) this.isOnMain = true;
        else if (this.currentFrame === 0) {
          // this.currentFrame = this.imgList.length;
          // this.currentFrame--;
        } else this.currentFrame--;
        this.resetCameraView();
      }
    },
    resetCameraView: function (str) {
      var camera = this.$refs.camera;
      let cameraRot = this.imgList[this.currentFrame].cameraRot;
      let x;
      let y;
      if (this.isOnMain) {
        x = cameraRot.main.x;
        y = cameraRot.main.y;
      } else {
        x = cameraRot.sub.x;
        y = cameraRot.sub.y;
      }

      camera.components["look-controls"].pitchObject.rotation.x = x;
      camera.components["look-controls"].yawObject.rotation.y = y;
    },
    reverse: function (str) {
      return str.split("").reverse().join("");
    },
    subTest: function (item) {
      console.log(item);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$blue: #3498db;
.ui-item {
  position: absolute;
  z-index: 2;
}

.ui-btn {
  cursor: pointer;
  color: unset;
  border: none;
  background: none;
  width: 50px;
  height: 50px;
  touch-action: manipulation;
  user-select: none;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  &:hover {
    opacity: 0.8;
  }
  &.toggle-screen {
    &.min {
      background-image: url("/img/icons/min-full.svg"); /* The image used */
    }
    &.full {
      background-image: url("/img/icons/full-full.svg"); /* The image used */
    }
  }
  &.next {
    background-image: url("/img/icons/arrow-full.svg"); /* The image used */
  }
  &.prev {
    background-image: url("/img/icons/arrow-full.svg"); /* The image used */
  }
}
.top {
  color: white;
  top: 10px;
  right: 10px;
  display: flex;
  .ui-btn {
    margin-right: 10px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.4s;
}
.bounce-leave-active {
  animation: bounce-in 0.2s reverse;
}
@keyframes bounce-in {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
.scene-border {
  position: absolute;
  top: 0;
  background: red;
  width: 100%;
  height: 100%;
  z-index: 11;
}
.frame-info {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
  color: white;
  border: 2px solid white;
  border-radius: 3px;
  background: linear-gradient(
    to right,
    rgba(0, 132, 176, 0.8),
    rgba(0, 179, 219, 0.8)
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 7px;
  .frame-title {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    @media only screen and (max-width: 900px) {
      font-size: 0.9rem;
    }
    max-width: 40vw;
  }
}
.dialog-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  z-index: 12;
  // align-items: center;
  .dialog {
    font-size: 1.2rem;
    max-width: 80vw;
    @media only screen and (max-width: 900px) {
      max-width: 600px;
      font-size: 1rem;
    }
    border-radius: 20px;
    margin: 20px;
    padding: 20px;
    padding-top: 60px;
    text-align: right;
    color: white;
    border: 4px solid white;
    box-shadow: 10px 20px 10px -6px rgba(61, 61, 61, 0.575); /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    position: relative;
    background: linear-gradient(
      to right,
      rgba(0, 132, 176, 0.8),
      rgba(0, 179, 219, 0.8)
    ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    // filter: blur(4px);
    .exit {
      position: absolute;
      top: 10px;
      right: 15px;
      cursor: pointer;
      border-radius: 50%;
      background: none;
      border: 4px solid white;
      height: 45px;
      width: 45px;
      transition: scale 0.3s;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-52%, -50%) rotate(45deg);
        width: 80%;
        height: 5px;
        border-radius: 5px;
        background: white;
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-52%, -50%) rotate(-45deg);
        width: 80%;
        height: 5px;
        border-radius: 5px;

        background: white;
      }
      &:hover {
        transform: scale(0.95);
        opacity: 0.8;
      }
    }
  }
  .text {
    overflow-y: auto;
    // height: 80%;
    font-size: 40px;
    @media only screen and (max-width: 1100px) {
      font-size: 20px;
    }
  }
}

.pdf {
  .pdf-wrap {
    overflow-y: scroll;
    height: 75vh;
    .placeholder {
      position: absolute;
      width: 100%;
    }
  }
  img {
    width: 100%;
    z-index: 4;
  }
}

.arrow {
  bottom: 30px;
  // font-size: 35px;
  font-weight: bold;
  &.next {
    left: 30px;
    @media only screen and (max-width: 1100px) {
      left: 22px;
    }
  }
  &.prev {
    right: 30px;
    @media only screen and (max-width: 1100px) {
      right: 22px;
    }
  }
}
.devtools {
  position: absolute;
  top: 0;
  background: white;
  z-index: 5;
  div {
    padding: 5px;
  }
  h4 {
    margin: 0;
  }
}
.next {
  transform: rotate(180deg);
}
</style>
