<template>
  <div id="app">
    <Scene
      :infoList="infoList"
      :imgList="imgList"
      :btns="btns"
      @toggleScreen="toggleScreen"
    />
  </div>
</template> 

<script>
import Scene from "./components/Scene.vue";

export default {
  name: "App",
  components: {
    Scene,
  },
  data: () => {
    return {
      imgList: [
        {
          step: 1,
          isMain: true,
          name: "1-m",
          hasSub: true,
          cameraRot: { main: { x: 0, y: -1.4 }, sub: { x: 0, y: -1.7 } },
          clickAreasMain: [
            {
              rot: "0 10 0",
              questionRot: "-5 -90 0",
              width: 1.5,
              height: 1.5,
              infoLoc: "bottom",
            },
          ],
          subAreas: [
            {
              rot: "0 -10 -5",
              questionRot: "5 -90 0",
              width: 1.5,
              height: 1.5,
              infoLoc: "top",
            },
          ],
        },
        {
          step: 2,
          isMain: true,
          name: "2-m",
          hasSub: true,
          cameraRot: { main: { x: 0.3, y: 4.2 }, sub: { x: 0, y: 4.7 } },
          clickAreasMain: [
            {
              rot: "0 -30 20",
              questionRot: "-8 -90 0",
              width: 1.2,
              height: 1.2,
              infoLoc: "bottom",
            },
          ],
          subAreas: [
            {
              rot: "0 0 0",
              questionRot: "-5 -90 0",
              width: 1.4,
              height: 1.4,
              infoLoc: "bottom",
            },
          ],
        },
        {
          step: 3,
          isMain: true,
          name: "3-m",
          hasSub: true,
          cameraRot: { main: { x: -0.2, y: 4.6 }, sub: { x: 0.2, y: 4.78 } },
          clickAreasMain: [
            {
              rot: "0 -5 -20",
              questionRot: "10 -90 0",
              width: 1.8,
              height: 1.5,
              infoLoc: "top",
            },
          ],
          subAreas: [
            {
              rot: "0 5 0",
              questionRot: "10 -90 0",
              width: 1.5,
              height: 1.2,
              infoLoc: "top",
            },
          ],
        },
        {
          step: 4,
          isMain: true,
          name: "4-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0, y: 4.47 } },
          clickAreasMain: [
            {
              rot: "0 -15 -5",
              questionRot: "0 -90 0",
              width: 2,
              height: 1.4,
              infoLoc: "top",
            },
          ],
        },
        {
          step: 5,
          isMain: true,
          name: "5-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: -0.2, y: 5.16 } },
          clickAreasMain: [
            {
              width: 1.5,
              height: 1.5,
              rot: "0 25 0",
              questionRot: "-10 -90 0",
              infoLoc: "bottom",
            },
          ],
        },
        {
          step: 6,
          isMain: true,
          name: "6-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: -0.2, y: 1.57 } },
          clickAreasMain: [
            {
              rot: "0 180 -27",
              questionRot: "20 -90 0",
              infoLoc: "top",
              width: 1.2,
              height: 1,
            },
          ],
        },
        {
          step: 7,
          isMain: true,
          name: "7-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.1, y: 1.52 } },
          clickAreasMain: [
            {
              rot: "3 180 6",
              questionRot: "0 -90 5",
              infoLoc: "top",
              width: 1.5,
              height: 1.4,
            },
          ],
        },
        {
          step: 8,
          isMain: true,
          name: "8-m",
          hasSub: true,
          cameraRot: { main: { x: -0.1, y: 4.25 }, sub: { x: -0.3, y: 4.7 } },
          clickAreasMain: [
            {
              rot: "0 -25 -30",
              questionRot: "20 -90 0",
              infoLoc: "top",
              width: 1.5,
              height: 1.5,
            },
          ],
          subAreas: [
            {
              rot: "0 -3 -15",
              questionRot: "0 -90 0",
              infoLoc: "top",
              width: 1.7,
              height: 1.5,
            },
          ],
        },

        {
          step: 9,
          isMain: true,
          name: "9-m",
          hasSub: true,
          cameraRot: { main: { x: 0, y: 5 }, sub: { x: -0.3, y: 4.7 } },
          clickAreasMain: [
            {
              rot: "0 14 -30",
              questionRot: "15 -90 0",
              infoLoc: "top",
              width: 1,
              height: 1,
            },
          ],
          subAreas: [
            {
              rot: "0 0 -20",
              questionRot: "0 -90 0",
              infoLoc: "top",
              width: 1.7,
              height: 1.7,
            },
          ],
        },
        {
          step: 10,
          isMain: true,
          name: "10-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.1, y: 4.6 } },
          clickAreasMain: [
            {
              rot: "0 -8 5",
              questionRot: "0 -90 0",
              infoLoc: "bottom",
              width: 1,
              height: 1.2,
            },
          ],
        },
        {
          step: 11,
          isMain: true,
          name: "11-m",
          hasSub: false,
          cameraRot: { main: { x: -0.2, y: 4.55 } },
          clickAreasMain: [
            {
              rot: "0 -9 -24",
              questionRot: "15 -90 0",
              infoLoc: "top",
              width: 3.2,
              height: 2.6,
            },
          ],
          subAreas: [],
        },
        {
          step: 12,
          isMain: true,
          name: "12-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: -0.4, y: 4.72 } },
          clickAreasMain: [
            {
              rot: "0 0 -55",
              questionRot: "10 -90 0",
              infoLoc: "top",
              width: 1.2,
              height: 1.2,
            },
          ],
        },
        {
          step: 13,
          isMain: true,
          name: "13-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.3, y: 4.55 } },
          clickAreasMain: [
            {
              rot: "0 -23 10",
              questionRot: "0 -80 0",
              infoLoc: "top",
              width: 0.6,
              height: 1.2,
            },
          ],
        },
        {
          step: 7,
          isMain: true,
          name: "7-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.1, y: 1.52 } },
          clickAreasMain: [
            {
              rot: "3 180 6",
              questionRot: "0 -90 4",
              infoLoc: "top",
              width: 1.5,
              height: 1.4,
            },
          ],
        },
        {
          step: 14,
          isMain: true,
          name: "14-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 6.3, y: 4.6 } },
          clickAreasMain: [
            {
              rot: "0 -2 15",
              questionRot: "-20 -90 0",
              infoLoc: "bottom",
              width: 0.8,
              height: 1.15,
            },
          ],
        },
        {
          step: 15,
          isMain: true,
          name: "15-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.3, y: 0.8 } },
          clickAreasMain: [
            {
              rot: "0 113 3",
              infoLoc: "top",
              questionRot: "0 -70 -5",
              width: 0.6,
              height: 0.8,
            },
          ],
        },
        {
          step: 10,
          isMain: true,
          name: "10-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.1, y: 4.6 } },
          clickAreasMain: [
            {
              rot: "0 -8 5",
              questionRot: "0 -90 0",
              infoLoc: "bottom",
              width: 1,
              height: 1.2,
            },
          ],
        },
        {
          step: 16,
          isMain: true,
          name: "16-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.3, y: 1.6 } },
          clickAreasMain: [
            {
              rot: "0 180 27",
              questionRot: "-35  -90 0",
              infoLoc: "bottom",
              width: 0.7,
              height: 0.75,
            },
          ],
        },
        {
          step: 13,
          isMain: true,
          name: "13-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.1, y: 5.1 } },
          clickAreasMain: [
            {
              rot: "0 23 -5",
              questionRot: "10 -90 0",
              infoLoc: "top",
              width: 1.7,
              height: 1.6,
            },
          ],
        },
        {
          step: 17,
          isMain: true,
          name: "17-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0, y: 1.4 } },
          clickAreasMain: [
            {
              rot: "0 170 -5",
              questionRot: "0 -90 0",
              infoLoc: "top",
              width: 3,
              height: 1.5,
              text: [
                "מערכת מי עיבוי קונדנסר בעבודה.",
                "• משאבה עובדת.",
                "• וסתי גובה באוטומט.",
              ],
            },
          ],
        },
      ],
      infoList: [
        {
          step: 1,
          main: [
            [
              "מערכת מי עיבוי קונדנסר בעבודה.",
              "• משאבה עובדת.",
              "• וסתי גובה באוטומט.",
            ],
          ],
          sub: ["יש לבדוק שהלחץ תקין."],
        },
        {
          step: 2,
          main: [
            [
              "במידת הצורך נמלא מים במעבה.",
              "מומלץ להשאיר ברז מילוי פתוח עד ההפעלה, לצורך סחרור.",
              "לחץ על הברז לפרטים.",
            ],
          ],
          sub: ["ברז מילוי למעבה."],
        },
        {
          step: 3,
          main: [
            [
              "מלכודת 8.5 בעבודה מלאה.",
              "יש לבדוק שהברזים פתוחים.",
              "לחץ כדי להתקרב.",
            ],
          ],
          sub: ["בדיקה: ברז ריקון לפלאש מתפקד."],
        },
        {
          step: 4,
          main: [
            [
              "מעבה קיטור אטימה.",
              "לפני יצירת ואקום במעבה הגדול יש להכניס את מעבה קיטור האטימה לעבודה.",
            ],
          ],
        },
        {
          step: 5,
          main: [
            ["קו קיטור 8.5:", "יש לוודא ניקוז מים מקו הקיטור."],
            ["קו קיטור 8.5:", "יש לוודא ניקוז מים מקו הקיטור."],
          ],
        },
        {
          step: 6,

          main: [["פתיחת קו 8.5."]],
        },
        {
          step: 7,
          main: [
            [
              "קו זה מזין את וסת  401PV – ווסת קיטור אטימה. ",
              "יש לוודא שהווסת סגור במצב ידני (MARK-6). ",
            ],
          ],
          sub: [""],
        },
        {
          step: 8,
          main: [
            [
              "קו זה מזין קיטור ליצירת ואקום במעבה קיטור אטימה.",
              "לחץ כדי לראות את שעון הלחץ.",
            ],
          ],
          sub: ["שעון לחץ."],
        },
        {
          step: 9,
          main: [
            [
              "בקו ה-VENT של מעבה קיטור אטימה קיים ברז ניקוז (נמצא בברך, מתחת לקונדנסר).",
              "הניקוז חייב להיות פתוח כדי לנקז מים ולהימנע מבלימה בקו.",
              "כאשר מתחיל לצאת קיטור, רצוי לצמצם מעט את הברז.",
              "לחצו על הניקוז כדי לראות מקרוב.",
            ],
          ],
          sub: ["ניקוז ברך ב-VENT."],
        },
        {
          step: 10,
          main: [
            [
              "נתחיל בהזרמת הקיטור עד לווסת הקיטור למעבה קיטור אטימה.",
              "נוודא שברז ניקוז 1-DB מתחת לבית הטורבינה פתוח",
              "(הניקוז השמאלי מבין השלושה).",
            ],
          ],
        },
        {
          step: 11,
          main: [["יש למלא מים בברך הברומטרית."]],
          sub: ["ץיש למלא מים בברך הברומטרית."],
        },

        {
          step: 12,
          main: [
            [
              "לאחר מילוי מים בברך הברומטרית, יש לפתוח את ווסת הקיטור למעבה באופן ידני.",
              "בתחילת הפעלת הטורבינה נעבוד עם הווסת הידני עד להתייצבות המערכת.",
              "הערך לשמירה: 480-",
            ],
            // ["שעוני ואקום מעבה קיטור אטימה", "הערך לשמירה: 480-"],
          ],
        },

        {
          step: 13,
          main: [
            ["שעוני ואקום מעבה קיטור אטימה."],
            // ["שעוני ואקום מעבה קיטור אטימה", "הערך לשמירה: 480-"],
          ],
        },
        {
          step: 7,
          main: [
            [
              "בשלב זה נתחיל בפתיחת וסת קיטור האטימה 401-PV באופן איטי עד להגעה ל-  SP=0.16",
              "כאשר הטורבינה מועמסת נשמור על ערך של:",
              "0.12-0.14",
            ],
          ],
          sub: [""],
        },
        {
          step: 14,
          main: [
            [
              "יש לוודא שהניקוז אחרי ווסת השחרור פתוח",
              "(נמצא מתחת לבית הטורבינה, ליד קו הקזה 1.8).",
              "ניקוז זה חייב להישאר פתוח כל הזמן.",
            ],
          ],
        },
        {
          step: 15,
          main: [
            ["ווסת שחרור."],
            // [
            //   "בשלב זה נתחיל בפתיחת וסת קיטור האטימה 401-PV באופן איטי עד להגעה ל-  SP=0.16",
            //   "כאשר הטורבינה מועמסת נשמור על ערך של:",
            //   "0.12-0.14",
            // ],
          ],
        },
        {
          step: 10,
          main: [["יש לסגור את ברז הניקוז."]],
        },
        {
          step: 16,
          main: [["לבדוק:", "• הנשם נקי.", "• לא יוצאים אדים."]],
        },
        {
          step: 13,
          main: [["וודא ואקום תקין."]],
        },
        {
          step: 17,
          main: [
            [
              "לאחר שבצענו את הבדיקות והכל תקין...",
              "אפשר ליצור ואקום במעבה הגדול.",
            ],
          ],
        },
      ],
      btns: [
        { frame: 1, top: "535", left: "80", title: "מי עיבוי" },
        { frame: 2, top: "0", left: "0", title: "ברז מילוי למעבה" },
        { frame: 3, top: "0", left: "50", title: "מלכודת קיטור 8.5" },
        { frame: 4, top: "535", left: "350", title: "מעבה קיטור אטימה" },
        { frame: 5, top: "625", left: "565", title: "קיטור 8.5" },
        { frame: 6, top: "625", left: "610", title: "קיטור 8.5" },
        { frame: 7, top: "462", left: "450", title: "ווסת קיטור אטימה pv401" },
        { frame: 8, top: "605", left: "400", title: "קיטור לטובת ואקום" },
        { frame: 9, top: "555", left: "555", title: "וונט מעבה קיטור אטימה" },
        { frame: 10, top: "442", left: "240", title: "ניקוז db1" },
        { frame: 11, top: "640", left: "288", title: "ברך ברומטרית" },
        { frame: 12, top: "640", left: "485", title: "ווסת קיטור למעבה" },
        { frame: 13, top: "495", left: "200", title: "משדר לחץ (ואקום)" },
        { frame: 14, top: "250", left: "600", title: "ניקוז למעבה dz1" },
        { frame: 15, top: "325", left: "520", title: "ווסת שחרור pv400" },
        { frame: 16, top: "0", left: "100", title: "נשם אוויר" },
        { frame: 17, top: "0", left: "150", title: "מעבה קיטור גדול" },
      ],
    };
  },
  methods: {
    toggleScreen: function () {
      let elem = document.body;
      // full/minimize screen toggle
      if (
        (document.fullScreenElement !== undefined &&
          document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined &&
          document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined &&
          !document.webkitIsFullScreen)
      ) {
        if (elem.requestFullScreen) {
          elem.requestFullScreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
* {
  box-sizing: border-box;
}
#app {
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
  // width: 100%;
  // height: 100%;
}

.loading-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #d5adc8;
  background-image: linear-gradient(315deg, #d5adc8 0%, #ff8489 74%);
  top: 0;
  z-index: 3;
}
.during-load {
  z-index: 5;
}
.lds-dual-ring {
  display: inline-block;
  width: 140px;
  height: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .txt {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 94px;
  height: 94px;
  margin: 8px;
  border-radius: 50%;
  border: 15px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.gradient {
  background: #00b4db; /* fallback for old browsers */
  background: linear-gradient(
    to right,
    #0083b0,
    #00b4db
  ); /* Chrome 10-25, Safari 5.1-6 */
}
</style>
